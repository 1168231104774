:root {
	--br: 4px;
    --pddng: 12px;
    --headerheight: 76px;
    --inputheight: 48px;
    --blue: #005af0;
    --transition: all 0.5s ease-out;
}

*, *::before, *::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background: #e2e5e6;
    line-height: 1.5;
}

h1 {
    font-size: 2em;
    line-height: 1.2;
    margin-bottom: .75em;
}
h2 {
    font-size: 1.5em;
    margin-bottom: .5em;
}

textarea, input, button {
    font-family: inherit;
    font-size: inherit;
    border: 0 none;
}
button {
    background: var(--blue);
    color: #FFF;
    cursor: pointer;
    border-radius: var(--br);
    line-height: calc(var(--inputheight) - 8px);
}
button[disabled] {
    opacity: .5;
    cursor: not-allowed;
}

p {
    margin-bottom: 1em;
}

ul {
    list-style: none;
    margin: 0 0 .5em 0;
}
ol {
    margin: 0 0 .5em 1.5em;
}
ul li {
    position: relative;
    padding: 0 0 .5em 1.5em;
}
ol li {
    position: relative;
    padding: 0 0 .5em .5em;
}
ul li::before {
    content: '';
    position: absolute;
    left: 0;
    top: .65em;
    width: 1em;
    height: 1px;
    background: var(--blue);
}
iframe {
    aspect-ratio: 16/9;
    width: 100%;
    height: auto;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}
p:has(iframe) {
    display: flex;
    justify-content: center;
}

code {
    display: block;
    background: #efefef;
    padding: 1em;
    margin: 1em 0;
}

.adaptive {
    margin: 1em auto;
    max-width: 1200px;
}
.adaptive img {
    width: 100%;
    height: auto;
}

.status_error {
    background: red;
}
.app_container {
    display: grid;
    height: 100vh;
    padding: var(--pddng);
    padding-top: 0;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: var(--headerheight) var(--inputheight) 1fr; 
    gap: var(--pddng);
    grid-template-areas:
      "app_header app_header app_header"
      "compared_site_form compared_site_form compared_site_form"
      "compared_list result_block result_block"; 
}
.app_header {
    grid-area: app_header;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "app_header_form app_header_info app_header_tg_button";
    gap: 8px;
    background: rgb(9,70,121);
    color: #FFF;
    border-radius: 0 0 var(--br) var(--br);
    padding: var(--pddng) 1em;
}
.app_header_form {
    grid-area: app_header_form;
    position: relative;
}
.app_header_form_input {
    width: 100%;
    height: 100%;
    padding: 0 .5em;
    border-radius: var(--br);
}
.app_header_form_submit {
    position: absolute;
    bottom: 4px;
    right: 4px;
    top: 4px;
    line-height: 1.2em;
    padding: 0 1em;
    border-radius: var(--br);
    font-size: .75em;
}
.app_header_info {
    grid-area: app_header_info;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}
.app_header_tg_button {
    grid-area: app_header_tg_button;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #FFF;
    color: #FFF;
    border-radius: var(--br);
    text-decoration: none;
}
.compared_site_form {
    grid-area: compared_site_form;
    position: relative;
}
.compared_site_form_input {
    line-height: var(--inputheight);
    width: calc(100% - var(--inputheight) - var(--pddng));
    border-radius: var(--br);
    padding: 0 1em;
}
.compared_site_form_submit {
    display: block;
    position: absolute;
    top: 4px;
    right: calc(var(--inputheight) + 4px + var(--pddng));
    bottom: 4px;
    padding: 0 1em;
}
.get_help {
    position: absolute;
    top: 0;
    right: 0;
    width: var(--inputheight);
    height: var(--inputheight);
    line-height: var(--inputheight);
    background: #23d5ab;
}
.compared_list {
    grid-area: compared_list;
    background: #FFF;
    padding: 1em;
    border-radius: var(--br);
}
.result_block {
    grid-area: result_block;
    position: relative;
    background: #000;
    color: #FFF;
    border-radius: var(--br);
    padding: 1em;
    overflow-y: auto;
}
.result_block a {
    color: lightblue;
    text-decoration: none;
}
.result_block a:hover {
    color: lightgreen;
}
.yellow {
    color: yellow;
}
.green {
    color: greenyellow;
}
.red {
    color: red;
}
.app_copy {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.5em;
    background: transparent;
}
.app_error {
    color: red;
}

.help_popup {
    position: fixed;
    inset: 0 0 0 0;
    z-index: 10;
    visibility: hidden;
}
.help_popup.active {
    visibility: visible;
}
.help_popup_overlay {
    position: absolute;
    inset: 0 0 0 0;
    background: rgba(0,0,0,.85);
    transition: var(--transition);
    opacity: 0;
    z-index: 1;
}
.help_popup.active .help_popup_overlay {
    opacity: 1;
}
.help_popup_content {
    position: absolute;
    inset: 40px;
    padding: 1em;
    background: #FFF;
    border-radius: var(--br);
    overflow-y: auto;
    z-index: 2;
    transition: var(--transition);
    transform: scale(0);
}
.help_popup.active .help_popup_content {
    transform: scale(1);
}
.help_popup_content_close {
    position: absolute;
    top: 0;
    right: 0;
    width: 41px;
    height: 41px;
    background: transparent;
    transform: rotate(45deg);
    z-index: 3;
    border-radius: 50%;
}
.help_popup_content_close::before, .help_popup_content_close::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 20px;
    width: 1px;
    background: #FFF;
}
.help_popup_content_close::after {
    top: 20px;
    bottom: auto;
    left: 0;
    right: 0;
    width: auto;
    height: 1px;
}

.app_download {
    display: inline-block;
}
.app_download svg {
    width: auto;
    height: 1em;
}
.app_download svg path {
    fill: lightblue;
}
.app_download:hover svg path {
    fill: lightgreen;
}

@media screen and (max-width: 1024px) {
    .app_container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: var(--headerheight) var(--inputheight) 1fr; 
        gap: var(--pddng);
        grid-template-areas: 
          "app_header app_header"
          "compared_site_form compared_site_form"
          "compared_list result_block"; 
    }
    .hide_on_1024 {
        display: none;
    }
}
@media screen and (max-width: 750px) {
    .app_container {
        grid-template-columns: 1fr;
        grid-template-rows: var(--headerheight) var(--inputheight) 1fr 3fr; 
        gap: var(--pddng);
        grid-template-areas: 
        "app_header"
        "compared_site_form"
        "compared_list"
        "result_block"; 
    }
    .hide_on_750 {
        display: none;
    }
    :root {
        --headerheight: 102px;
    }
    .app_header {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-template-areas:
        "app_header_form app_header_tg_button"
        "app_header_info app_header_info";
    }
}
@media screen and (max-width: 450px) {
    :root {
        --headerheight: 120px;
    }
    .app_header {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-areas:
            "app_header_form"
            "app_header_tg_button"
            "app_header_info";
    }
}

/* loader */
.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 3s ease infinite;
    z-index: 1000;
    transition: var(--transition);
    transform: translateY(-10px);
}
.loader.active {
    transform: translateY(0);
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
/* loader */